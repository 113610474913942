<template>
    <div class="contaner1">
      <div @click="mineMessage" class="messageTitle2">
            <img class="messageIcon" src="../../assets/message.png" />
            <div>联系客服</div>
          </div>
        <div class="header_title1">会员资格可凭借在购购平台.工会会员福利推广专区，消费{{dataList.union_member_fee}}凭证获取会员资格！</div>
        <div class="price">
            <div>价值:</div>
            <div class="number">¥{{dataList.union_member_fee}}</div>
        </div>
        <div class="payStatus">
            <van-radio-group v-model="radio">
                <div class="item">
                    <img class="img2" src="../../assets/Slice52.png"/>
                    <div class="payText">凭证上传</div>
                    <div class="text1">(请上传购物凭证）</div>
                    <van-radio name="2" icon-size="24px"></van-radio>
                </div>
            </van-radio-group>
        </div>
        <div class="footer_box"  v-if="radio==2">
            <div class="">
                <div class="text2">消费凭证上传:</div>
                <div>
                    <div class="select_box">
                        <uploadImg :isNumber="isNumber" :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
                    </div>
                </div>
            </div>
            <div  v-if="radio==2" style="margin-top:20px">
                <div class="text2">备注</div>
                <textarea style="color:#000;font-size:24px" placeholder-class="text10" v-model="textfrom"></textarea>
            </div>
        </div>
        <!-- <div class="submit" v-if="radio==1" @click="submit(1)">{{dataList.union_member_fee}}元 确认支付</div> -->
        <div class="submit" @click="submit(2)">提交</div>
    </div>
</template>

<script scoped type="">
import * as apiCommon from "@/api/common";

import wx from "weixin-js-sdk";
// import { setTimeout } from 'timers/promises';
import uploadImg from "../../components/uploadImg";
export default {
  data() {
    return {
      value: "",
      radio: "1",
      textfrom: "",
      dataList: {},
      from: null,
      from1: null,
      from2: null,
      fileList5: [],
      imgUrl: "",
      imgToken: "",
      imgConfig: "{ useCdnDomain: true }",
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:10
       },
      isNumber:true,
    };
  },
  created() {
    document.title = "支付页面";
    this.getSetting();
    this.getToken();
    this.from = localStorage.getItem("imgName");
    this.from1 = JSON.parse(localStorage.getItem("temp2"));
    this.from2 = JSON.parse(localStorage.getItem("img"));
    console.log(this.from, this.from1, this.from2);
  },
  components: {
    uploadImg
  },
  methods: {
    cloneBtn(){
      this.fileList5 = [];
    },
    uploadImg(img){
      this.fileList5 = img
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      this.uploadData.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    async getSetting() {
      let data = {
        config: ["union_member_fee"]
      };
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        console.log(res.data, "111111");
        this.dataList = res.data;
      }
    },
    // 返回 Promise
    beforeRead(e) {
      console.log(e, "file");
      let img = e

      this.upload(1, img);
    },
    async upload(index, img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      console.log(fd, "fd");
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      this.fileList5 = [{ url: this.imgUrl }];

      this.$forceUpdate();
    },
       mineMessage() {
      console.log(1, "2");
      //       wx.config({
      //    debug: false,
      //    appId: '',
      //    timestamp: ,
      //    nonceStr: '',
      //    signature: '',
      //    jsApiList: [
      //      // 所有要调用的 API 都要加到这个列表中
      //      'getLocation',
      //      'hideOptionMenu',
      //      'closeWindow'
      //    ]
      //  });
      // wx.ready(function() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
      // });
    },
    async submit(index) {
      let data = {
        audit_type: 1,
        audit_info: {
          username: this.from1.username.value,
          sex: this.from1.sex.value,
          nation: this.from1.nation.value,
          politics: this.from1.politics.value,
          education: this.from1.education.value,
          identity: this.from1.identity.value,
          phone: this.from1.phone.value,
          address: this.from1.address.value,
          experience: this.from1.experience.value,
          remark: this.from1.remark.value,
          signature: this.from,
          userImg: this.from2[0][0].url,
          identityCard_front: this.from2[1][0].url,
          identityCard_reverse: this.from2[2][0].url
        },
        pay_type: this.radio == 1 ? 2 : 1
      };
      if (this.radio == 2) {
        data.pay_info = {
          img: this.fileList5[0].url,
          text: this.textfrom
        };
      }
      let res1 = await apiCommon.getConfig();
      let tableData = res1.data;
      // tableData.jsApiList.push("chooseWXPay");
      // tableData.url = " https://trade.healthplatform.xyz/#/lador/pay";
      console.log(tableData,"tableData")
      wx.config(tableData);
      let that = this;
      let res = await apiCommon.orderPay(data);
      //   console.log(res);
      if (res.code == 0) {
        let order_id = res.data.audit.audit_id;
        //  wx.ready(() => {
        if (index == 1) {
          wx.chooseWXPay({
            timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
            package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: res.data.pay_info.paySign, // 支付签名
            success(res) {
              if (res.errMsg == "chooseWXPay:ok") {
                localStorage.removeItem("imgName");
                localStorage.removeItem("temp2");
                localStorage.removeItem("img");
                 that.$toast("支付成功");
                  // that.$router.push("./auditDetails?id="+order_id);
                  // that.$router.push(`./orderDetail?id=${order_id}`);
                  that.$router.push({
                    name: "auditDetails",
                    params: { id: order_id, isPgoods: true }
                  });
                  // window.location.href=` https://trade.healthplatform.xyz/lador/#/lador/orderDetail?id=${order_id}`;
                  window.location.href=` https://trade.healthplatform.xyz/lador/#/lador/orderDetail?id=${order_id}`;
              } else {
                alert(res.errMsg);
                that.$toast("支付失败", "middle");
              }
            },
            cancel: function(res) {
              that.$toast("支付取消", "middle", res);
            }
          });
        } else {
          that.$toast("提交成功");
          localStorage.removeItem("imgName");
          localStorage.removeItem("temp2");
          localStorage.removeItem("img");
          that.$router.push(`./orderDetail?id=${order_id}`);
        }
      } else {
        that.$toast(res.error);
      }
    }
  }
};
</script>
<style scoped>
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle2 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
}
.cloneBtn{
  width: 20px;height: 20px;
  border-radius:50%;
  background-color: #fff;
  text-align: center;
  line-height:20px;
  position: absolute;
  top: 0;right: 0;z-index: 2
}
.van-uploader__upload{
  width: 160px!important;
  height: 124px!important;
  background-color: transparent;
  margin-left: 30px!important;
}
.box_clone {
  width: 40px;
  /* height: 40px; */
  border-radius: 40px;
  background-color: #fff;
  position: absolute;
  top: 0;right: 0
}
.box_clone .imgs{
  width: 30px;height: 30px;
  border-radius: 30px;
  margin: 5px
  
}
.contaner1 {
  padding-bottom: 30px;
  background-color: #fff;
}
.contaner1 .header_title1 {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #d43634;
  opacity: 1;
  padding: 33px 45px 0 57px;
}
.text10{
  color:#000
}
.contaner1 {
}
.price {
  width: 750px;
  height: 115px;
  line-height: 115px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 0px;
  margin-top: 93px;
  justify-content: space-between;
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 41px 0 67px;
  display: flex;
  box-sizing: border-box;
}
.number {
  font-size: 40px;
  color: #000000;
}
.text {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.5);
  margin: 27px 0 27px 67px;
}
.payStatus {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.payStatus .img1 {
  width: 68px;
  height: 68px;
  margin: 26px 35px 0 47px;
}
.payStatus .img2 {
  width: 60px;
  height: 60px;
  margin: 26px 35px 0 47px;
}
.item {
  display: flex;
  width: 658px;
  height: 113px;
  line-height: 113px;
  font-size: 30px;
  border-bottom: 1px solid #eee;
  margin-left: 47px;
}
.wechart {
  margin-right: 320px;
}
.payText {
}
.text1 {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 72px 0 22px;
}
.user_info {
  width: 636px;
  height: 169px;
  border: 2px solid #d43634;
  opacity: 1;
  border-radius: 11px;
  margin: 20px 57px 0;
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #d43634;
  opacity: 1;
  padding: 14px;
  box-sizing: border-box;
}
.user_info div {
  margin-bottom: 17px;
}
.codeIcon {
  width: 192px;
  height: 192px;
}
.text2 {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #d43634;
  opacity: 1;
  margin: 31px 0 40px 0;
}
.footer_box {
  margin: 0 56px 0 65px;
}
.select_box {
  width: 174px;
  height: 171px;
  opacity: 1;
  border-radius: 10px;
  padding-left: 35px;
  padding-top: 15px;
  box-sizing: border-box;
  position: relative;
}
.select_box .imgBox{
  width: 160px;
  height: 124px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0
}
.imgFlexd {
  width: 160px;
  height: 124px;
  /* padding-left: 42px; */
  /* padding-top: 20px */
}
.van-uploader__wrapper {
  height: 100%;
}
.select_box img {
  width: 89px;
  height: 77px;
  /* margin: 23px 35px; */
  position: relative;
}
textarea {
  width: 629px;
  height: 312px;
  border: 2px solid #eee;
  opacity: 1;
  border-radius: 7px;
}
.submit {
  width: 662px;
  height: 106px;
  background: #ff6633;
  opacity: 1;
  border-radius: 10px;
  font-size: 45px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 106px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 75px auto 0;
}
</style>
